import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import MainNavigation from "./components/UIComponents/Navigation/MainNavigation";
import FooterNavigation from "./components/UIComponents/Footer/FooterNavigation";
import ScrollToTop from "./components/PageComponents/ScrollTop";
// import LoaderComponent from "./components/UIComponents/Loader/Loader";
// import ScrollToTop from "./components/ScrollTop";

const Home = lazy(() => import("./pages/HomePage"));
const Products = lazy(() => import("./pages/ProductPage"));
const AutoDealer = lazy(() => import("./pages/AutoDealerPage"));
const Candidates = lazy(() => import("./pages/CandidatesPage"));
const Recruiters = lazy(() => import("./pages/RecruitersPage"));
const SolutionsPage = lazy(() => import("./pages/SolutionsPage"));
const CompanyPage = lazy(() => import("./pages/CompanyPage"));
// const ContactPage = lazy(() => import("./pages/ContactPage"));
const ContactSupportPage = lazy(() => import("./pages/ContactSupportPage"));
const CompliancePage = lazy(() => import("./pages/CompliancePage"));
const RequestDemo = lazy(() => import("./pages/RequestDemo"));
const FAQPage = lazy(() => import("./pages/FAQPage"));
const PeopleResource = lazy(() => import("./pages/PeopleResource"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUsePage"));
const ServiceAgreement = lazy(() => import("./pages/ServiceAgreementPage"));

const App = () => (
  <div>
    <Router>
      <MainNavigation />
      <Suspense>
        {/* <LoaderComponent /> */}
        <PageWrapper>
          <ScrollToTop />
          <main>
            <Routes>
              <Route exact path="/" element={<Home />}></Route>
              <Route exact path="/product" element={<Products />}></Route>
              <Route path="/solutions" element={<SolutionsPage />}></Route>
              <Route path="/auto" element={<AutoDealer />}></Route>
              <Route path="/candidates" element={<Candidates />}></Route>
              <Route path="/recruiters" element={<Recruiters />}></Route>
              <Route path="/company" element={<CompanyPage />}></Route>
              {/* <Route path="/contact-us" element={<ContactPage />}></Route> */}
              <Route path="/contact-us" element={<RequestDemo />}></Route>
              <Route path="/faq" element={<FAQPage />}></Route>
              <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
              <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
              <Route
                path="/service-agreement"
                element={<ServiceAgreement />}
              ></Route>
              <Route
                path="/contact-support"
                element={<ContactSupportPage />}
              ></Route>
              <Route path="/compliance" element={<CompliancePage />}></Route>
              <Route
                path="/people-resources"
                element={<PeopleResource />}
              ></Route>
              {/* <Route path="/" element={<Navigate replace to="/home" />} /> */}
            </Routes>
          </main>
          <FooterNavigation />
        </PageWrapper>
      </Suspense>
    </Router>
  </div>
);

function PageWrapper({ children }) {
  const location = useLocation();

  React.useEffect(() => {
    document.body.className = ""; // Clear all classes
    if (location.pathname === "/contact-us") {
      document.body.classList.add("demo-page");
    }
  }, [location]);

  return <>{children}</>;
}

export default App;
